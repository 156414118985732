import React, { useEffect, useState } from 'react';
import ModalVideo from 'react-modal-video';

export default function AllMixes({ mixesList }) {
    let isOpenArrayCopy = new Array(mixesList.length).fill(false);
    const [isOpenArray, setIsOpenArray] = useState(new Array(mixesList.length).fill(false));

    const closeVideoModal = (i) => {
        isOpenArrayCopy[i] = false;
        setIsOpenArray(isOpenArrayCopy);
    }

    const openVideoModal = (i) => {
        isOpenArrayCopy[i] = true;
        setIsOpenArray(isOpenArrayCopy);
    }

    return (
        <div className='all-mixes-container'>
            {mixesList.map((mix, i) => (
                <>
                    <ModalVideo 
                        channel='youtube' 
                        autoplay 
                        isOpen={isOpenArray[i]} 
                        videoId={mix.fields.video_id} 
                        // onClose={() => setOpen(false)} 
                        onClose={() => closeVideoModal(i)}
                        key={`0${i}`} 
                    />
                    <div 
                        className='mix-container' 
                        onClick={()=> openVideoModal(i)}
                        key={i}
                    >
                        <div className='mix-cover' style={{ backgroundImage: `url(${mix.fields.cover_small[0].url})` }}/>
                        <div className='mix-info'>
                            <h2>{mix.fields.name}</h2>
                            <p>{mix.fields.year}</p>
                        </div>
                    </div>
                </>
            ))}
        </div>
    )
}