import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/Atchoumlogo.svg';
import logoYoutube from '../images/youtube.svg';
import logoInstagram from '../images/instagram.svg';
import logoFacebook from '../images/facebook.svg';
import logoEmail from '../images/email.svg';

export default function Header() {
    const pathname = window.location.pathname;
    const [currentUrl, setCurrentUrl] = useState(pathname);

    return (
        <header className="header">
            <Link to='/'>
                <img 
                    src={logo} 
                    className="logo" 
                    alt="logo" 
                    onClick={() => setCurrentUrl('/')} 
                />
            </Link>
            <nav>
                <ul>
                    <Link to='/'>
                        <li 
                            onClick={() => setCurrentUrl('/')} 
                            className={currentUrl === '/' ? 'active' : ''}
                        >
                            à la une
                        </li>
                    </Link>
                    <Link to='/toutes-les-emissions/'>
                        <li 
                            onClick={() => setCurrentUrl('/toutes-les-emissions/')} 
                            className={currentUrl === '/toutes-les-emissions/' ? 'active desktop' : 'desktop'}
                        >
                            toutes les émissions
                        </li>
                    </Link>
                    <Link to='/toutes-les-emissions/'>
                        <li 
                            onClick={() => setCurrentUrl('/toutes-les-emissions/')} 
                            className={currentUrl === '/toutes-les-emissions/' ? 'active mobile' : 'mobile'}
                        >
                            Voir tout
                        </li>
                    </Link>
                </ul>
            </nav>
            <div className='bottom-gradient mobile-gradient' />
            <div className='bottom-blue-block'/>
            <div className='social-bar-container'>
                <a href='https://www.youtube.com/channel/UC_H7C2nVJL8n1dLQemhXxXw' target='_blank' rel='noopener noreferrer'><div className='social-icon youtube' style={{ backgroundImage:`url(${logoYoutube})` }}/></a>
                <a href='https://www.instagram.com/radioatchoum/' target='_blank' rel='noopener noreferrer'><div className='social-icon instagram' style={{ backgroundImage:`url(${logoInstagram})` }}/></a>
                <a href='https://www.facebook.com/radioatchoum' target='_blank' rel='noopener noreferrer'><div className='social-icon facebook' style={{ backgroundImage:`url(${logoFacebook})` }}/></a>
                <a href='mailto:radioatchoum@gmail.com'><div className='social-icon mail' style={{ backgroundImage:`url(${logoEmail})` }}/></a>
            </div>
        </header>
    )
}